import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout";
import logoEcologi from "../assets/logo-ecologi-colour.png";
import logoPlayingForThePlanet from "../assets/logo-p4tp-dark.png";
import logoCountUsIn from "../assets/logo-countUsIn.png";
import logoBCorp from "../assets/logo-bcorp.png";
import cssClass from "./environment.module.scss";

const Environment = () => {
  const data = useStaticQuery(graphql`
    query {
      imageHero: file(relativePath: { eq: "env-hero.png" }) {
        id
        childImageSharp {
          fluid(maxWidth: 3000, quality: 70) {
            ...GatsbyImageSharpFluid_withWebp
            ...GatsbyImageSharpFluidLimitPresentationSize
          }
        }
      }
    }
  `);

  return (
    <Layout className={cssClass.environment}>
      <div className={cssClass.hero}>
        <div className={cssClass.heroInner}>
          <h1 className={cssClass.heading}>So you want to join the AIWRL eh, kid?</h1>
        </div>
        <Img fluid={data.imageHero.childImageSharp.fluid} className={cssClass.heroImage} />
      </div>
      <div className={cssClass.intro}>
        <div className={cssClass.inner}>
          <p>Are you passionate about saving the planet?</p>
          <p>Are you energised to help the environment?</p>
          <p>Do you want to join Alba and Ines in their fight to protect what they love?</p>
        </div>
      </div>
      <div className={cssClass.main}>
        <div className={cssClass.inner}>
          <p className={cssClass.lookBelow}>Well, take a look at some of the great initiatives below and, like Alba, you can make a difference!</p>
          <ul className={cssClass.items}>
            <li className={`${cssClass.item} ${cssClass.ecologi}`}>
              <img className={cssClass.icon} src={logoEcologi} alt="" />
              <h2 className={cssClass.title}><a href="https://ecologi.com/albasforest">1 Million Treeeeees!</a></h2>
              <p>With the support of Alba’s players, we are setting a goal to plant 1 million trees. For every copy of the game ‘Alba: A Wildlife Adventure’ that is downloaded or sold, one tree will be planted in Alba’s Forest. You can help us reach our goal.</p>
              <p>Partnering with Ecologi, Alba’s players will support a reforestation project in Madagascar, run by the non-profit Eden Reforestation Projects, who have already planted 385 million trees globally.</p>
              <p>Restoring mangroves is a very effective, natural climate solution. Mangrove forests are known to capture up to four times as much carbon per acre than tropical rainforests, and are a natural barrier for coastal erosion, providing habitats for tropical fish, and work to filter water of pollutants.</p>
              <p>Check out Alba’s Forest to learn more or donate a tree.</p>
            </li>
            <li className={`${cssClass.item} ${cssClass.count}`}>
              <img className={cssClass.icon} src={logoCountUsIn} alt="" />
              <h2 className={cssClass.title}><a href="https://alba.count-us-in.org/">Helping the Planet? Count Us In!</a></h2>
              <p>ustwo games is a founding partner in Count Us In, an incredible mission to inspire 1 billion citizens to reduce their carbon pollution and deliver global change.</p>
              <p>Follow in the footsteps of Alba, and make a real difference today! With 16 science supported climate-positive steps to choose from, Join the #TeamAlba community on Count Us In, and be part of something even bigger!</p>
              <p>Join your fellow team mates and pledge to Use Less Plastic, Walk &amp; Cycle More or Repair &amp; Re-use and see how the steps you take can create a huge impact with the help of others!</p>
            </li>
            <li className={`${cssClass.item} ${cssClass.planet}`}>
              <img className={cssClass.icon} src={logoPlayingForThePlanet} alt="" />
              <h2 className={cssClass.title}><a href="https://playing4theplanet.org">Playing for The Planet!</a></h2>
              <p>Ustwo games - the studio behind Alba: A Wildlife Adventure - is committed to harnessing the power of games and gamers to take action on the climate crisis. After joining the Playing for the Planet Alliance in 2020, ustwo games have made a commitment to:</p>
              <ul>
                <li>Inspire action through play by integrating themes of environmental conservation, sustainability and green nudges in games</li>
                <li>Reduce their carbon footprint by 30% by the end of 2022</li>
                <li>Offset 100% of their carbon footprint from 2021 onwards</li>
                <li>Work with our landlord to move to sustainable energy sources within our corporate offices</li>
              </ul>
            </li>
            <li className={`${cssClass.item} ${cssClass.bcorp}`}>
              <img className={cssClass.icon} src={logoBCorp} alt="" />
              <h2 className={cssClass.title}><a href="https://bcorporation.uk">Did you know that we are a B Corp?</a></h2>
              <p>ustwo is a B Corporation (or B Corp), and we want to put our values into Action!</p>
              <p>B Corps are for-profit businesses that meet the highest verified standards of social and environmental performance, transparency, and accountability. Through Alba, and these amazing initiatives, we hope to create a measurable positive impact in the world.</p>
            </li>
          </ul>
        </div>
      </div>
    </Layout>
  );
};

export default Environment;
